<template>
    <section>
      <div class="mb-4">
        <h2>{{ solicitud.codigo }}</h2>
        <h2>Solicitud de compra en línea de bienes o servicios</h2>
        <h4>Unidad de Retención de Talento Humano</h4>
        <h4>Ejercicio {{ anioFiscal }}</h4>
      </div>

      <div class="my-8">
        <v-row>
            <v-col cols="6" sm="4" md="3">
                <v-btn block text color="success" @click="dialogoConfirmacion = true"><v-icon>mdi-send</v-icon> Aprobar solicitud</v-btn>
            </v-col>
            <v-col cols="6" sm="4" md="3">
                <v-btn block text color="red" @click="dialogoCancelacion = true"><v-icon>mdi-cancel</v-icon> Denegar solicitud</v-btn>
            </v-col>
        </v-row>
      </div>

      <ConfirmationDialogComponent
          :show="dialogoConfirmacion"
          btnConfirmar="Aceptar"
          title="¿Deseas aprobar la solicitud?"
          message="De confirmarse la siguiente acción, los cambios no serán reversibles."
          @close="dialogoConfirmacion = false"
          @confirm="dialogoConfirmacion = false"
      />

      <ConfirmationDialogComponent
          :show="dialogoCancelacion"
          btnConfirmar="Aceptar"
          title="¿Deseas denegar la solicitud?"
          message="De confirmarse la siguiente acción, los cambios no serán reversibles."
          @close="dialogoCancelacion = false"
          @confirm="dialogoCancelacion = false"
      />
  
      <v-tabs v-model="tabSeleccionada" fixed-tabs background-color="transparent">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#insumos" class="primary--text" v-if="space == 1 || space == 3">
          <v-icon>mdi-package-variant-closed</v-icon>
          Insumos
        </v-tab>
        <v-tab href="#proveedores" class="primary--text">
          <v-icon>mdi-store-outline</v-icon>
          Proveedores
        </v-tab>
      </v-tabs>
  
      <v-tabs-items v-model="tabSeleccionada">
        <v-tab-item :value="'insumos'">
          <section class="text-center">
            <v-btn
              class="mx-2 border-0"
              :id="'btn_fondo_' + item.id"
              outlined
              @click="cargarInsumosFondos(item.id)"
              v-for="(item, index) in fondosAsignados"
              :key="index"
            >
              {{ item.nombre }}
            </v-btn>
          </section>
  
          <MetricaFondosComponent
            :fuenteFondos="fondosSeleccionados"
            :totalSolicitud="totalInsumosSolicitados"
          />
  
          <div class="py-8">
            <router-link :to="{ name: 'agregar-insumo-compra' }" class="d-block mb-4">
              <v-btn color="secondary">
                <v-icon>mdi-plus</v-icon>
                Agregar insumo
              </v-btn>
            </router-link>
            <h4 class="mt-4">Lista de insumos</h4>
  
            <ListadoInsumosComponent />
          </div>
        </v-tab-item>
        <v-tab-item :value="'proveedores'">
          <div class="py-8">
            <h4>Lista de proveedores</h4>
            <tabla-proveedores-component :mostrarAprobarProveedores="space"/>
          </div>
        </v-tab-item>
      </v-tabs-items>
  
      <v-row>
        <v-col cols="12" sm="6" md="2">
          <router-link :to="{ name: 'list-solicitudes-compra' }">
            <v-btn outlined color="primary" class="mb-4" block>
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </section>
  </template>
  
  <style lang="scss" scoped>
  :deep(.theme--light.v-tabs-items) {
    background: transparent !important;
  }
  :deep(.theme--light.v-data-table) {
    background: transparent !important;
  }
  a {
    text-decoration: none;
  }
  
  .border-0 {
    border: 0;
  }
  </style>
  
  <script>
  import { mapMutations, mapState } from 'vuex'
  import ListadoInsumosComponent from './components/ListadoInsumosComponent'
  import MetricaFondosComponent from './components/MetricaFondosComponent'
  import TablaProveedoresComponent from './components/TablaProveedoresComponent.vue'
  import ConfirmationDialogComponent from '../../components/ConfirmationDialogComponent.vue'
  
  export default {
    name: 'aprobarSolicitudCompraView',
    components: {
      ListadoInsumosComponent,
      MetricaFondosComponent,
      TablaProveedoresComponent,
      ConfirmationDialogComponent,
    },
    props: {},
    data: () => ({
      solicitud: {
        codigo: '01-2022-S423',
      },
      tabSeleccionada: null,
      fondosSeleccionados: {},
      dialogoConfirmacion: false,
      dialogoCancelacion: false,
    }),
    computed: {
      ...mapState(['anioFiscal']),
      ...mapState('comprasModule', [
        'insumosASolicitar',
        'fondosAsignados',
      ]),
      totalInsumosSolicitados() {
        let total = 0
        for (let insumo of this.insumosASolicitar) total += insumo.total
        return total
      },
      space() {
          return this.$route.query.space;
      }
    },
    watch: {},
    methods: {
      ...mapMutations('comprasModule', [
        'setInsumosASolicitar',
        'setFondosAsignados',
      ]),
      getColor(estado) {
        if (estado == 'Aprobado') return 'vigente'
        else if (estado == 'Pendiente') return 'pendiente'
        else return 'rechazado'
      },
      cargarFondosAsignados() {
        this.setFondosAsignados([
          {
            id: 1,
            nombre: 'Fondo general',
            asignado: 15000,
            disponible: 13000,
          },
          {
            id: 2,
            nombre: 'Donación',
            asignado: 6000,
            disponible: 6000,
          },
        ])
  
        setTimeout(() => {
          if (this.fondosAsignados.length > 0) {
            this.cargarInsumosFondos(this.fondosAsignados[0].id)
          }
        })
      },
      cargarInsumosFondos(idFondo) {
        const btns = document.querySelectorAll('[id^=btn_fondo_]')
  
        for (let btn of btns)
          if (!btn.classList.contains('border-0')) btn.classList.add('border-0')
  
        const btn = document.querySelector('#btn_fondo_' + idFondo)
        btn.classList.remove('border-0')
  
        setTimeout(() => {
          this.setInsumosASolicitar([
            {
              id: 1,
              mercancia: 'Lapiceros azules',
              unidad_medida: 'Unidad',
              total: 70,
              estado: {
                nombre: 'Listo',
                color: 'green',
              },
              enlace_compra: 'https://google.com',
            },
          ])
        }, 100)
  
        const fondo = this.fondosAsignados.filter((value) => {
          if (value.id === idFondo) return value
        })
  
        if (fondo.length > 0) this.fondosSeleccionados = fondo[0]
      },
      enviarSolicitudCompra() {
        this.$router.replace({ name: 'list-solicitudes-compra' })
      },
    },
    mounted() {},
    created() {
      setTimeout(() => {
        this.cargarFondosAsignados()
      }, 100)
    },
  }
  </script>
  