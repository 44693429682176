<template>
  <section>
    <v-data-table :headers="encabezados" :items="insumosASolicitar">
      <template v-slot:[`item.total`]="{ item }">
        {{ formatearMoneda(item.total) }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <label
          class="d-block rounded-lg text-center white--text"
          :style="'background: ' + item.estado.color"
        >
          {{ item.estado.nombre }}
        </label>
      </template>
      <template v-slot:[`item.enlace_compra`]="{ item }">
        <a
          class="d-block text-center"
          :href="item.enlace_compra"
          target="_blank"
        >
          Click aquí
        </a>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon :data-id="item.id">
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span> Ver </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ListadoInsumosComponent",
  data: () => ({
    encabezados: [
      {
        text: "Correlativo",
        value: "id",
      },
      {
        text: "Mercancia",
        value: "mercancia",
      },
      {
        text: "Unidad de medida",
        value: "unidad_medida",
      },
      {
        text: "Total ($)",
        value: "total",
      },
      {
        text: "Estado",
        value: "estado",
      },
      {
        text: "Enlace a compra",
        value: "enlace_compra",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
      },
    ],
  }),
  watch: {},
  computed: {
    ...mapState("comprasModule", ["insumosASolicitar"]),
  },
  methods: {
    formatearMoneda(cantidad) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
      }).format(cantidad);
    },
  },
};
</script>