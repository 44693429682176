<template>
    <section>
        <v-row justify="center">
            <v-col cols="12" sm="4" md="3" class="text-center">
                <h3>${{ fuenteFondos.disponible }}</h3>
                <p class="border-success">Fondo general disponible</p>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="text-center">
                <h3>${{ totalSolicitud }}</h3>
                <p class="border-warning">Fondos necesarios para solicitud</p>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="text-center">
                <h3>${{ fuenteFondos.disponible - totalSolicitud }}</h3>
                <p class="border-success">Saldo de fondo general</p>
            </v-col>
        </v-row>
    </section>
</template>

<style lang="scss" scoped>
:deep(.theme--light.v-sheet) {
    background: transparent;
    box-shadow: none;
}

.border-success {
    border-bottom: 4px solid #57cc99 !important;
}

.border-warning {
    border-bottom: 4px solid #FFEAA7 !important;
}
</style>

<script>
    export default {
        name: 'MetricaFondosComponent',
        components: {},
        props: {
            fuenteFondos: Object,
            totalSolicitud: Number
        },
        data: () => ({}),
        computed: {},
        watch: {},
        methods: {},
        mounted() {},
        created() {},
    }
</script>